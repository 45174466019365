<!-- 移动端登录页面,使用NutUI -->
<template>
  <div class="login">
    <div class="login-header">
      <div class="login-header-title">用户登录</div>
    </div>
    <div class="login-content">
      <div class="login-content-input">
        <nut-input v-model="loginform.username" placeholder="请输入手机号" clearable @on-clear="clear">
          <template #left>
            <Add></Add>
          </template>
        </nut-input>
      </div>
      <div class="login-content-input">
        <nut-input v-model="loginform.password" placeholder="请输入密码" clearable @on-clear="clear">
          <template #left>
            <Order></Order>
          </template>
        </nut-input>
      </div>
      <!-- 记住密码 -->
      <div style="margin-top: 10px;margin-bottom: 10px;">
        <nut-checkbox label="记住密码" v-model="remember">记住密码</nut-checkbox>
      </div>
      <div class="login-button">
        <!-- 左边注册按钮，右边登陆 -->
        <div class="login-content-button1">
          <nut-button type="info" @click="showRegister">注册</nut-button>
          <nut-dialog title='验证手机号及学生姓名注册' v-model:visible="registerVisible" okText="验证注册" @Ok="register">
            <nut-input v-model="registerform.username" placeholder="请输入手机号" clearable @on-clear="clear"
              required></nut-input>
            <nut-input v-model="registerform.name" placeholder="请输入学生姓名" clearable @on-clear="clear"
              required></nut-input>
            <nut-input v-model="registerform.password" placeholder="请输入密码" clearable @on-clear="clear"
              required></nut-input>
            <!-- 重新输入密码 -->
            <nut-input v-model="registerform.password2" placeholder="请再次输入密码" clearable @on-clear="clear"
              required></nut-input>
          </nut-dialog>
        </div>
        <div class="login-content-button">
          <nut-button type="success" @click="login">登录</nut-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createVNode } from 'vue';
import { Add, Order } from '@nutui/icons-vue';
import { showToast } from '@nutui/nutui';
import { showDialog } from '@nutui/nutui';
// import '@nutui/nutui/dist/packages/toast/style';

export default {
  name: 'LoginPage',
  components: {
    Add,
    Order
  },
  data() {
    return {
      registerVisible: false,
      remember: true,
      loginform: {
        username: '',
        password: '',
      },
      registerform: {
        username: '',
        mobile: '',
        name: '',
        password: '',
        password2: ''
      }
    };
  },
  mounted() {
    this.account()
  },
  methods: {
    // 显示注册弹窗
    showRegister() {
      this.registerVisible = true
    },
    // 获取localStorage中的用户名和密码
    account() {
      let username = localStorage.getItem('username')
      let password = localStorage.getItem('password')
      if (username && password) {
        this.loginform.username = username
        this.loginform.password = password
        this.remember = true
      }
    },
    // 触发登录事件
    login() {
      if (this.loginform.username === '' || this.loginform.password === '') {
        showToast.fail('用户名或密码不能为空');
      } else {
        // 获取sessionStorage中的code，如果有则将其添加到loginUser中
        let code = window.sessionStorage.getItem('code')
        console.log(code);
        if (code) {
          this.loginform.token = code
        }
        if (this.remember) {
          // 记住密码
          localStorage.setItem('username', this.loginform.username)
          localStorage.setItem('password', this.loginform.password)
        } else {
          // 清除记住的密码
          localStorage.removeItem('username')
          localStorage.removeItem('password')
        }
        this.axios.post('user/login/', this.loginform
        ).then(res => {
          if (res.data.code === 200) {
            showToast.success('登录成功');
            // ? 登陆成功，存储 token 到window.sessionStorage中
            window.sessionStorage.setItem('token', res.data.token)
            // 登陆成功后，清除存储的code
            window.sessionStorage.removeItem('code')
            this.$router.push('/welcome');
            // 获取个人信息，存储到sessionStorage中
            this.axios.get('/user/my/').then((res) => {
              console.log(res);
              window.sessionStorage.setItem('username', res.data.username)
            });
          } else {
            showToast.fail('登录失败,请检查账号密码');
          }
        }).catch(err => {
          console.log(err);
          showToast.fail('登录失败,请检查账号密码');
          this.clear()
        }
        );
      }
    },
    // 触发注册事件
    register() {
      console.log(this.registerform);
      if (this.registerform.username === '' || this.registerform.password === '' || this.registerform.name === '') {
        showToast.fail('手机号或学生姓名或密码不能为空');
      } else {
        // 判断两次密码是否一致
        if (this.registerform.password !== this.registerform.password2) {
          showToast.fail('两次密码不一致');
        } else {
          // 将username赋值给mobile
          this.registerform.mobile = this.registerform.username
          // 弹出窗口确认注册
          showDialog({
            title: '注册账号',
            content: createVNode('span', { style: { color: 'red' } }, '确认以输入的手机号注册吗？'),
            showCancelButton: true,
            onOk: () => {
              this.registerUser()
            },
            onCancel: () => {
              showToast.fail('取消注册');
            }
          })
        }
      }
    },
    clear() {
      this.username = '';
      this.password = '';
    },
    // 注册用户
    registerUser() {
      this.axios.post('user/reg/', this.registerform
      ).then(res => {
        console.log(res)
        if (res.status === 200) {
          showToast.success('注册成功');
        } else {
          showToast.fail('注册失败');
        }
      }).catch(err => {
        console.log(err);
        showToast.fail('注册失败,请检查密码是否大于八位');
      }
      )
    }
  },
  setup() {
    return {};
  }
};
</script>

<style scoped>
.login {
  width: 100%;
  height: 70vh;
  background-color: #fff;
  align-items: center;
  justify-content: center;
}

.login-header {
  width: 100%;
  height: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-header-title {
  font-size: 18px;
  font-weight: bold;
}

.login-content {
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-content-input {
  width: 80%;
  margin-bottom: 20px;
}

.login-button {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.login-content-button1 {
  width: 100%;
}

.login-content-button {
  width: 100%;
}
</style>